/*global gsap, ScrollTrigger,ScrollToPlugin, particlesJS, animationPath */

// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import lottie from 'lottie-web';
// import ScrollMagic from 'scrollmagic';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/**
 * Import scripts from Custom Divi blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/divi/example-block/index.js';

/**
 * Import scripts from Custom Elementor widgets
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/elementor/example-widget/index.js';

/**
 * Import scripts from Custom ACF Gutenberg blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/gutenberg/acf-example-block/index.js';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  // Hide label on focus
  let textareaField = document.querySelectorAll('.contact__form .gfield');
  textareaField.forEach(function (field) {
    if (field.querySelector('textarea') || field.querySelector('input')) {
      let fieldInput;
      if (field.getAttribute('id') == 'field_1_11') {
        fieldInput = field.querySelector('textarea');
      } else {
        fieldInput = field.querySelector('input');
      }
      fieldInput.addEventListener('focus', function () {
        if (this.value == '') {
          field.querySelector('.gfield_label').classList.add('is-rise');
        }
      });
      fieldInput.addEventListener('focusout', function () {
        if (this.value == '') {
          field.querySelector('.gfield_label').classList.remove('is-rise');
        }
      });
    }
  });
  $('.testimonials__slider').slick({
    dots: false,
    infinite: false,
    arrows: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  });
  /**
   * Set red bg to body on scroll
   */
  if ($('.homepage').length ? $(this).length : 0) {
    gsap.registerPlugin(ScrollTrigger);
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: '.services',
        start: 'top top',
        endTrigger: '.video__wrapper',
        end: 'bottom 95%',
        scrub: 0.2,
        // markers: { startColor: 'green', endColor: 'white' },
      },
    });
    tl.to('.services-bg', {
      opacity: 1,
    });
    if (window.innerWidth < 768) {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: '.services',
          start: 'top top',
          end: 'bottom 95%',
          scrub: 0.2,
          duration: 5,
          // markers: { startColor: 'green', endColor: 'red' },
        },
        ease: 'power1.inOut',
      });
      tl.to('.services-bg', {
        opacity: 1,
      });
    }

    /**
     * Floating images
     */
    particlesJS('particles-js', {
      interactivity: {
        detectsOn: 'canvas',
        events: {
          onClick: {
            enable: false,
            mode: [],
          },
          onDiv: {
            selectors: [],
            enable: false,
            mode: [],
            type: 'circle',
          },
          onHover: {
            enable: false,
            mode: 'trail',
            parallax: {
              enable: false,
              force: 2,
              smooth: 10,
            },
          },
          resize: false,
        },
      },
      particles: {
        color: {
          value: '#c40000',
          animation: {
            h: {
              count: 0,
              enable: true,
              offset: 0,
              speed: 50,
              sync: false,
            },
            s: {
              count: 0,
              enable: false,
              offset: 0,
              speed: 1,
              sync: true,
            },
            l: {
              count: 0,
              enable: false,
              offset: 0,
              speed: 1,
              sync: true,
            },
          },
        },
        links: {
          blink: false,
          color: {
            value: 'random',
          },
          consent: false,
          distance: 100,
          enable: false,
          frequency: 1,
          opacity: 0,
          shadow: {
            blur: 5,
            color: {
              value: '#00ff00',
            },
            enable: false,
          },
          triangles: {
            enable: false,
            frequency: 1,
          },
          width: 1,
          warp: false,
        },
        line_linked: {
          enable: false,
        },
        move: {
          angle: {
            offset: 0,
            value: 90,
          },
          attract: {
            distance: 200,
            enable: false,
            rotate: {
              x: 3000,
              y: 3000,
            },
          },
          decay: 0,
          distance: {},
          direction: 'none',
          drift: 0,
          enable: true,
          gravity: {
            acceleration: 9.81,
            enable: false,
            inverse: false,
            maxSpeed: 50,
          },
          path: {
            clamp: true,
            delay: {
              random: {
                enable: false,
                minimumValue: 0,
              },
              value: 0,
            },
            enable: false,
            options: {},
          },
          outModes: {
            default: 'out',
            bottom: 'out',
            left: 'out',
            right: 'out',
            top: 'out',
          },
          random: false,
          size: false,
          speed: 1,
          spin: {
            acceleration: 0,
            enable: false,
          },
          straight: false,
          trail: {
            enable: false,
            length: 10,
            fillColor: {
              value: '#000000',
            },
          },
          vibrate: false,
          warp: false,
        },
        number: {
          density: {
            enable: true,
            area: 5,
            factor: 10,
          },
          limit: 0,
          value: 4,
        },
        opacity: {
          random: {
            enable: true,
            minimumValue: 0.1,
          },
          value: {
            min: 0.1,
            max: 1,
          },
          animation: {
            count: 0,
            enable: true,
            speed: 0.5,
            sync: false,
            destroy: 'none',
            startValue: 'random',
            minimumValue: 0.1,
          },
        },
        rotate: {
          random: {
            enable: true,
            minimumValue: 0,
          },
          value: 20,
          animation: {
            enable: false,
            speed: 0,
            sync: false,
          },
          direction: 'clockwise',
          path: false,
        },
        shadow: {
          blur: 10,
          color: {
            value: '#cccccc',
          },
          enable: true,
          offset: {
            x: 12,
            y: 12,
          },
        },
        shape: {
          polygon: {
            nb_sides: 5,
          },
          image: {
            src: 'wp-content/themes/amplify-for-lawyers/assets/images/Ellipse-1.png',
            width: 100,
            height: 100,
          },
          // type: 'image',
          type: ['image'],
        },
        // size: {
        //   random: {
        //     enable: false,
        //     minimumValue: 7,
        //   },
        //   value: {
        //     min: 5,
        //     max: 10,
        //   },
        //   animation: {
        //     count: 0,
        //     enable: true,
        //     speed: 2,
        //     sync: false,
        //     destroy: 'none',
        //     startValue: 'random',
        //     minimumValue: 1,
        //   },
        // },
      },
      zLayers: 1,
    });
    /**
     * Gallery movement
     */
    const tl_second = gsap.timeline({
      scrollTrigger: {
        trigger: '.gallery-section',
        start: 'top 80%',
        // end: '+=300',
        scrub: 1,
        // pin: true,
        duration: { min: 4 },
        delay: 0,
        toggleActions: 'restart none reset none',
        // markers: { startColor: 'green', endColor: 'red' },
      },
    });
    tl_second.fromTo(
      '.gallery-images',
      { translateX: 0, transformOrigin: '0 100%' },
      { translateX: 100, duration: 1.95 },
      0.2
    );
    /**
     * Cursor image inside box
     * */
    gsap.registerPlugin(ScrollToPlugin);
    $('.impact').on('mousemove vmousemove', '.impact__card', function (e) {
      let rect = this.getBoundingClientRect();
      let cursor = $(this).find('.cursor');
      let x = e.clientX - rect.x;
      let y = e.clientY - rect.y;
      cursor.css({ opacity: 1 });
      cursor.css({ left: x + 'px' });
      cursor.css({ top: y + 'px' });
      gsap.to(
        cursor,
        {
          '--rotation-xy': `${parseInt(x + y)}deg`,
          ease: 'none',
        },
        0
      );
    });
    $('.impact').on('mouseleave', '.impact__card', function () {
      let cursor = $(this).find('.cursor');
      cursor.css({ opacity: 0 });
    });
  }
  /**
   * About images animation
   */
  if ($('.main-about').length ? $(this).length : 0) {
    const tl_about = gsap.timeline({
      scrollTrigger: {
        trigger: '.banner-images',
        start: '-150',
        end: '+=10',
        scrub: 1.5,
        // pin: true,
        duration: { min: 4 },
        // delay: 0,
        ease: 'power4.out',
        toggleActions: 'restart none reset none',
        // markers: { startColor: 'green', endColor: 'red' },
      },
    });
    if (window.innerWidth < 768) {
      tl_about.to('.image-1', {
        translateX: 20,
        translateY: -3,
        rotation: -13.027,
        transformOrigin: '50% 50%',
        // duration: 2,
        delay: 0,
      });
      tl_about.to('.image-2', {
        translateX: -64,
        translateY: -20,
        rotation: -6,
        transformOrigin: '50% 50%',
        // duration: 1.5,
        delay: -0.4,
      });
      tl_about.to('.image-3', {
        translateX: -144,
        translateY: -14,
        rotation: 2.3,
        transformOrigin: '50% 50%',
        // duration: 1,
        delay: -0.5,
      });
      tl_about.to('.image-4', {
        translateX: -230,
        translateY: -2.6,
        rotation: 9.42,
        transformOrigin: '50% 50%',
        // duration: 1,
        delay: -0.6,
      });
    } else {
      tl_about.to('.image-1', {
        translateX: 0,
        translateY: -3,
        rotation: -13.027,
        transformOrigin: '50% 50%',
        // duration: 2,
        delay: 0,
      });
      tl_about.to('.image-2', {
        translateX: -150,
        translateY: -25,
        rotation: -6,
        transformOrigin: '50% 50%',
        // duration: 1.5,
        delay: -0.4,
      });
      tl_about.to('.image-3', {
        translateX: -305,
        translateY: -19,
        rotation: 2.3,
        transformOrigin: '50% 50%',
        // duration: 1,
        delay: -0.5,
      });
      tl_about.to('.image-4', {
        translateX: -465,
        translateY: 0,
        rotation: 9.42,
        transformOrigin: '50% 50%',
        // duration: 1,
        delay: -0.6,
      });
    }
  }
  /**
   * Map animation
   */
  if ($('.footprint').length ? $(this).length : 0) {
    //  The following two numbers define the angles (in degrees)
    //  that the transformation area will be rotated at about
    //  X and Y axes when the cursor reaches the right (for Y)
    //  and the top (for X) borders of the tracking area.
    let maxRotationDegreesX = 2;
    let maxRotationDegreesY = 2;

    //  This effectively defines the distance along X axis between
    //  the reference point and the projection plane. The greater the
    //  number, the greater the transformation area's projection
    //  is deformed due to perspective.
    // let perspectivePx = 1000;

    //  These variables are requried to translate screen coordinates
    //  supplied by mouse event into the coordinate system with
    //  its reference point placed in the center of the tracking area.
    let trackingAreaShiftX = $('.footprint__map').offset().left;
    let trackingAreaShiftY = $('.footprint__map').offset().top;

    let halfTrackingAreaWidth = $('.footprint__map').width() / 2;
    let halfTrackingAreaHeight = $('.footprint__map').height() / 2;

    let mouseCoordinateCorrectionX = trackingAreaShiftX + halfTrackingAreaWidth;
    let mouseCoordinateCorrectionY =
      trackingAreaShiftY + halfTrackingAreaHeight;

    $('.footprint__map').on('mousemove', function () {
      //  Translate cooridnates of the mouse ponter
      let x = event.clientX - mouseCoordinateCorrectionX;
      let y = event.clientY - mouseCoordinateCorrectionY;
      //  Calculate degrees of rotation with respect to their maximum values
      let rotationY = (x * maxRotationDegreesX) / halfTrackingAreaWidth;
      let rotationX = (-y * maxRotationDegreesY) / halfTrackingAreaHeight;
      //  Construct CSS transform setting string
      let transform = `rotate3d(1, 0, 0, ${rotationX}deg) rotate3d(0, 1, 0, ${rotationY}deg)`;
      //  Apply the transformation
      setTimeout(function () {
        $('.footprint__map svg').css('-webkit-transform', transform);
        $('.footprint__map svg').css('-moz-transform', transform);
        $('.footprint__map svg').css('-ms-transform', transform);
        $('.footprint__map svg').css('-o-transform', transform);
        $('.footprint__map svg').css('transform', transform);
      }, 100);
    });
  }
  /**
   * Hero Animation
   */
  gsap.registerPlugin(ScrollTrigger);
  if ($('.js-animation').length ? $(this).length : 0) {
    let lottieAnimation;
    $('.js-animation').each(function () {
      let $this = $(this),
        animationJson = $(this).data('animation');
      // animationRepeat = $(this).data('repeat');
      if (animationJson) {
        lottieAnimation = lottie.loadAnimation({
          container: $this[0],
          renderer: 'svg',
          loop: 0, // true/false
          autoplay: false,
          path: animationPath + animationJson,
          rendererSettings: {
            progressiveLoad: true,
          },
        });
        ScrollTrigger.create({
          trigger: $this[0],
          start: 'top 50%',
          // endTrigger: '.seo-section',
          // end: '+=100',
          onEnter: () => lottieAnimation.play(),
          // markers: { startColor: 'green', endColor: 'red' },
        });
      }
    });
  }
  if ($('.js-animation-seo').length ? $(this).length : 0) {
    let lottieAnimation;
    $('.js-animation-seo').each(function () {
      let $this = $(this),
        animationJson = $(this).data('animation');
      // animationRepeat = $(this).data('repeat');
      if (animationJson) {
        lottieAnimation = lottie.loadAnimation({
          container: $this[0],
          renderer: 'svg',
          loop: 0, // true/false
          autoplay: false,
          path: animationPath + animationJson,
          rendererSettings: {
            progressiveLoad: true,
          },
        });
        ScrollTrigger.create({
          trigger: $this[0],
          start: 'top 25%',
          onEnter: () => lottieAnimation.play(),
        });
      }
    });
    lottieAnimation.addEventListener('complete', () => {
      ScrollTrigger.refresh();
      console.log('end');
    });
  }
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
      $('.header').toggleClass('is-active');
      $('body').toggleClass('fixed');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
      $('.header').removeClass('is-active');
      $('body').removeClass('fixed');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
